<template><v-dialog v-model="dialog_open" persistent max-width="1000px"><v-card>
	<v-card-title>
		<b>{{edited_shift.vh_shift_id?'Edit Shift Record':'Create Shift(s)'}}</b> 
		<span v-if="edited_shift.vh_shift_id>0" class="ml-2" style="font-size:16px">(Shift ID {{edited_shift.vh_shift_id}})</span>
		<v-spacer v-if="edited_shift.vh_shift_id==0"/>
		<span v-if="edited_shift.vh_shift_id==0"><v-checkbox class="mt-0 pt-0" :label="'Use batch add interface'" v-model="batch_add" hide-details></v-checkbox></span>
	</v-card-title>
	<v-card-text>
		<div v-show="!batch_add">
			<div class="mt-0 d-flex align-center">
				<div style="width:250px">
					<v-select v-model="edited_shift.date" :items="available_dates" label="Date" dense outlined hide-details append-icon="fas fa-calendar-day"></v-select>
				</div>
				<div v-if="edited_shift.date" style="width:180px" class="ml-4">
					<v-select v-model="edited_shift.start_time" :items="times" label="Start Time" dense outlined hide-details append-icon="fas fa-clock"></v-select>
				</div>
				<div v-if="edited_shift.date" style="width:180px" class="ml-4">
					<v-select v-model="edited_shift.end_time" :items="times" label="End Time" dense outlined hide-details append-icon="fas fa-clock"></v-select>
				</div>
				<v-spacer/>
			</div>
			<div class="mt-4 d-flex align-center">
				<div v-if="edited_shift.date||this.is_system_admin" style="flex:0 0 240px" class="mr-4"><v-autocomplete outlined dense hide-details label="County" v-model="edited_shift.county" :items="available_counties"></v-autocomplete></div>
				<div v-if="edited_shift.date"><v-autocomplete outlined dense hide-details label="Location(s)" :multiple="new_shifts" v-model="vh_location_id_selection" :items="shift_locations" @input="autocomplete_input=null" :search-input.sync="autocomplete_input"></v-autocomplete></div>
			</div>
			<div v-if="edited_shift.date" class="mt-4 d-flex align-center">
				<div class="mr-2">Priority Level:</div>
				<div style="width:290px"><v-select outlined dense hide-details v-model="edited_shift.priority" :items="priorities"></v-select></div>
				<div class="ml-6 mr-2">Status:</div>
				<div style="width:180px"><v-select outlined dense hide-details v-model="edited_shift.status" :items="statuses"></v-select></div>
				<v-spacer/>
				<div v-if="!new_shifts" style="width:180px"><v-text-field outlined dense hide-details label="LBJ Shift ID" v-model="edited_shift.lbj_shift_database_id" placeholder="" autocomplete="new-password"></v-text-field></div>
			</div>
			<div v-if="edited_shift.date" class="mt-4 d-flex align-center"><v-autocomplete outlined dense hide-details label="Shift Leader (optional)" v-model="edited_shift.admin_email" :items="admin_emails"></v-autocomplete></div>
			<div v-if="edited_shift.date" class="mt-4 d-flex align-center"><v-autocomplete outlined dense hide-details label="Volunteer" v-model="edited_shift.volunteer_email" :items="volunteer_emails"></v-autocomplete></div>
		</div>
		<div v-show="batch_add" class="black--text">
			<div>Format for each line (separate each value with a tab, space, or comma):</div>
			<div class="mt-1 mb-3 ml-4"><code>lbj_location_id</code>, <code>date</code> (e.g. “11-08”), <code>start time</code> (e.g. “09:00”), <code>end time</code> (e.g. “13:00”), <code>volunteer email</code> (optional)</div>
			<div class="d-flex align-center mb-3">
				<div class="mr-3"><b>Initial status</b> for unfilled shifts:</div>
				<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="batch_status" mandatory>
					<v-btn small light value="not_open" @click.stop="" class="k-toggle-btn-active-class-off">  Not Open  </v-btn>
					<v-btn small light value="open" @click.stop="">Open</v-btn>
				</v-btn-toggle>
			</div>
			<div class="my-2">Notes:
				<ul>
					<li>County admins can only create shifts for locations in their county</li>
					<li>If a shift already exists with the lbj_location_id, date, start time, and end time specified in a line, the shift will be <i>updated</i> with the new volunteer’s email (or the email will be cleared if no email is provided)</li>
				</ul>
			</div>
			<v-textarea outlined hide-details auto-grow v-model="batch_shifts" rows="10" class="k-new-location-textarea"></v-textarea>
		</div>
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="secondary" @click="$emit('close')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Done</v-btn>
		<v-spacer></v-spacer>
		<v-btn v-if="edited_shift.vh_shift_id" color="red darken-2" dark @click="delete_shift"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Delete Shift</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save {{edited_shift.vh_shift_id?'Edits':'Shift(s)'}}</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AutocompleteEmailMixin from '../mixins/AutocompleteEmailMixin.js'

export default {
	components: { },
	mixins: [AutocompleteEmailMixin],
	props: {
		shift_being_edited: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		edited_shift: {},
		date_menu: false,
		start_time_menu: false,
		end_time_menu: false,
		location_input: '',
		vh_location_id_selection: [],
		batch_add: false,
		batch_shifts: '',
		batch_status: 'not_open',
		autocomplete_input: '',	// this makes the v-autocomplete clear typed text after selecting an item
	}},
	computed: {
		...mapState(['user_info', 'users', 'locations', 'shifts', 'site_data', 'times']),
		...mapGetters(['is_system_admin']),
		available_dates() {
			if (this.edited_shift.county) {
				if (this.edited_shift.county.toLowerCase() == 'hotline') return this.$store.getters.available_dates_hotline
				else return this.$store.getters.available_dates
			} else if (this.$store.getters.available_dates.length > this.$store.getters.available_dates_hotline.length) return this.$store.getters.available_dates 
			else return this.$store.getters.available_dates_hotline
		},
		statuses() { return U.shift_status_select_items() },
		priorities() {
			let arr = U.shift_priority_select_items()
			// if we're creating new shift(s), start with this item
			if (this.new_shifts) arr.unshift({
				value: 0,
				text: 'Default priority for location(s)'
			})
			return arr
		},

		// true if we're creating new shift(s); false if editing an existing shift
		new_shifts() { return (this.shift_being_edited.vh_shift_id == 0) },

		available_counties() {
			if (this.is_system_admin) return this.site_data.counties
			else return this.user_info.counties
		},

		election_date_chosen() { return this.edited_shift.date == this.site_data.election_date },
		shift_locations() {
			if (empty(this.edited_shift.county)) return []

			let arr = []
			// if we're creating new shift(s), start with an 'all' item, with text based on whether or not election day is chosen as the date
			if (this.new_shifts) arr.push({
				value: 0,
				text: this.election_date_chosen ? 'All Election Day Locations' : 'All Early Voting Locations'
			})

			for (let o of this.locations) {
				if (o.county != this.edited_shift.county) continue
				// filter location_type (ev/ed) based on date
				if (this.site_data.election_date == this.edited_shift.date) {
					// we're setting shift(s) for election day
					if (o.location_type != 'ed') continue
				} else {
					// we're setting shift(s) for early voting
					if (o.location_type != 'ev') continue
				}
				arr.push({value: o.vh_location_id, text: o.location})
			}

			arr.sort((a,b)=>{
				return a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
			})

			return arr
		},
		volunteer_emails() {
			let arr = []
			for (let u of this.users) {
				// only show users for this county
				if (!u.matches_county(this.edited_shift.county)) continue
				// if (u.county != this.edited_shift.county) continue

				let o = {value: u.email}
				if (!empty(u.name_last)) {
					o.text = sr('$1 ($2, $3)', u.email, u.name_last, u.name_first)
				} else {
					o.text = u.email
				}
				arr.push(o)
			}
			return arr
		},
		admin_emails() {
			let arr = []
			for (let u of this.users) {
				// only show users for this county
				if (!u.matches_county(this.edited_shift.county) && !u.is_system_admin()) continue

				// TODO: also only show only county admins?
				if (!u.has_role('county_admin')) continue

				let o = {value: u.email}
				if (!empty(u.name_last)) {
					o.text = sr('$1 ($2, $3)', u.email, u.name_last, u.name_first)
				} else {
					o.text = u.email
				}
				arr.push(o)
			}
			return arr
		},
	},
	watch: {
		vh_location_id_selection (val) {
			if (val.length > 1) {
				// if 0 is first in the array, the user just chose a specific location, so remove 0
				if (val[0] == 0) {
					this.vh_location_id_selection.shift()
				} else if (val[val.length-1] == 0) {
					// else if 0 is last in the array, the user just chose "all", so remove everything except 0
					this.vh_location_id_selection = [0]
				}
			}
		},
		'edited_shift.county'(val) {
			// when county is chosen, update in local storage
			this.$store.commit('lst_set', ['default_county', val])
		},
	},
	created() {
		this.edited_shift = new Shift(this.shift_being_edited)
		// if this is a new shift/batch of shifts,
		if (this.new_shifts) {
			// set vh_location_id_selection to an array, because multiple locations could be chosen
			this.vh_location_id_selection = [0]
			// and set priority to 0, which means use the default priority for the location
			this.edited_shift.priority = 0
		} else {
			// else select vh_location_id_selection to the single vh_location_id
			this.vh_location_id_selection = this.edited_shift.vh_location_id
		}
	},
	mounted() {
		// DEBUG
		vapp.admin_shift_edit = this
	},
	methods: {
		save_edits() {
			if (this.batch_add) {
				this.save_batch_adds()
				return
			}

			if (this.edited_shift.volunteer_email) this.edited_shift.volunteer_email = $.trim(this.edited_shift.volunteer_email.toLowerCase())
			if (this.edited_shift.admin_email) this.edited_shift.admin_email = $.trim(this.edited_shift.admin_email.toLowerCase())

			if (empty(this.edited_shift.county)) {
				this.$alert('You must enter a county for the shift.')
				return
			}

			if (empty(this.edited_shift.date)) {
				this.$alert('You must enter a date for the shift.')
				return
			}

			if (empty(this.edited_shift.start_time) && !empty(this.edited_shift.end_time)) {
				this.$alert('If you enter an end time, you must also enter a start time.')
				return
			}

			if (!empty(this.edited_shift.start_time) && !empty(this.edited_shift.end_time) && this.edited_shift.end_time < this.edited_shift.start_time) {
				this.$alert('The end time must be later than the start time.')
				return
			}

			if (this.vh_location_id_selection[0] == 0) {
				// if user chose 'all', send all location_ids through
				this.edited_shift.vh_location_id = []
				for (let o of this.shift_locations) {
					if (o.value != 0) this.edited_shift.vh_location_id.push(o.value)
				}
			} else {
				if (this.new_shifts) this.edited_shift.vh_location_id = this.vh_location_id_selection.concat([])
				else this.edited_shift.vh_location_id = this.vh_location_id_selection
			}

			// if volunteer is specified and status is 'not_open' or 'open', set to 'filled'
			if (this.edited_shift.volunteer_email && (this.edited_shift.status == 'not_open' || this.edited_shift.status == 'open')) {
				this.edited_shift.status = 'filled'
			}

			// if volunteer is NOT specified and status is 'filled', set to 'open'
			if (!this.edited_shift.volunteer_email && (this.edited_shift.status == 'filled')) {
				this.edited_shift.status = 'open'
			}

			// console.log(this.edited_shift)
			// return

			if (U.check_for_shift_overlap(this.shifts, this.locations, this.edited_shift)) return

			// note that the save_shift action/save_shifts service will take care of the cases where vh_location_id is an array, or 0
			this.$store.dispatch('save_shift', {shift: this.edited_shift}).then((result)=>{
				// if we're creating new, don't close after saving, so the user can set up more shifts if they want to
				if (!this.new_shifts) this.$emit('close') 
				else {
					let msg = sr('$1 shift(s) created or updated', result)
					this.$alert(msg).then(y=>{
						this.$emit('close')
					})
				}
			})
		},

		delete_shift() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to delete this shift?',
			    acceptText: 'Delete',
				acceptColor: 'red',
				// dialogMaxWidth: 800
			}).then(y => {
				this.$store.dispatch('delete_shifts', {vh_shift_ids: [this.edited_shift.vh_shift_id]}).then((result)=>{
					this.$emit('close')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		save_batch_adds() {
			let lines = this.batch_shifts.split(/\n/)
			let records = []
			let line_number = 1
			let replacements_count = 0
			let replacements_list = ''
			for (let line of lines) {
				line = $.trim(line)
				if (empty(line)) continue

				// split line by comma or tab
				let arr = line.split(/[,\s]+/)

				// we must get at least 4 values
				if (arr.length < 4) {
					console.log(arr, arr.length)
					this.$alert(sr('Error on line $1: this line does not include at least 4 values. ($2)', line_number, line))
					return
				}

				let o = {
					lbj_location_id: $.trim(arr[0]),
					date: $.trim(arr[1]),
					start_time: $.trim(arr[2]),
					end_time: $.trim(arr[3]),
					volunteer_email: arr[4] ? $.trim(arr[4]) : '',
				}

				// validate values (lbj_location_id will be validated below
				if (o.date.search(/^\d+-\d+$/) == -1) {
					this.$alert(sr('Error on line $1: invalid date. ($2)', line_number, line))
					return
				}
				if (o.start_time.search(/^\d\d:\d\d$/) == -1) {
					this.$alert(sr('Error on line $1: invalid start time (remember that 9:00 AM is 09:00, and 1:00 PM is 13:00). ($2)', line_number, line))
					return
				}
				if (o.end_time.search(/^\d\d:\d\d$/) == -1) {
					this.$alert(sr('Error on line $1: invalid end time (remember that 9:00 AM is 09:00, and 1:00 PM is 13:00). ($2)', line_number, line))
					return
				}
				if (o.volunteer_email && o.volunteer_email.search(/^.*?\@.*?\.\w+$/) == -1) {
					this.$alert(sr('Error on line $1: invalid email. ($2)', line_number, line))
					return
				}

				// if email is not given, status for the shift will be this.batch_status
				o.status = this.batch_status
				// if email *is* given...
				if (o.volunteer_email) {
					// make sure user's email exists; allow for lbj emails
					let user = this.users.find(x=>x.email == o.volunteer_email || x.lbj_email == o.volunteer_email)
					if (!user) {
						this.$alert(sr('Error on line $1: couldn’t find a user in our system with the given email. ($2)', line_number, line))
						return
					}
					// make sure the volunteer_email is the user's main email
					o.volunteer_email = user.email

					// and set status to 'filled'
					o.status = 'filled'
				}

				// determine location_type (ed or ev) based on date
				let location_type = (o.date == this.site_data.election_date) ? 'ed' : 'ev'

				// get location from lbj_location_id and make sure it exists
				let location = this.locations.find(x=>x.lbj_location_id == o.lbj_location_id && x.location_type == location_type)
				if (!location) {
					this.$alert(sr('Error on line $1: this line’s lbj_location_id was not found, or is not valid for the given date. ($2)', line_number, line))
					return
				}
				// set vh_location_id and county; set priority to location's default priority
				o.vh_location_id = location.vh_location_id
				o.county = location.county
				o.priority = location.default_priority

				// if this isn't a system admin, make sure the location's county matches their county
				if (!this.is_system_admin) {
					if (!this.user_info.matches_county(location.county)) {
						this.$alert(sr('Error on line $1: this line’s location does not match your county [$2]. ($3)', line_number, this.user_info.counties.join(', '), line))
						return
					}
				}

				// if a shift with the given location, date, start time, and end time already exists, update
				let existing_shift = this.shifts.find(x=>x.vh_location_id == o.vh_location_id && x.date == o.date && x.start_time == o.start_time && x.end_time == o.end_time)
				if (existing_shift) {
					o.vh_shift_id = existing_shift.vh_shift_id
					++replacements_count
					replacements_list += sr('<li>$1</li>', line)
				}

				records.push(o)

				++line_number
			}

			if (records.length == 0) {
				this.$emit('close')
				return
			}

			/*
586814	11-08	09:00	13:00	anandi@pepperwilliams.com
			*/

			console.log(records)

			let msg
			if (replacements_count > 0) {
				msg = sr('This will insert $2 new shift(s) and <b>replace (clear the status and volunteer email for)</b> $1 already-existing shift(s) (see below). Proceed?<div class="mt-2">Replaced shifts:</div><ul class="mt-2">$3</ul>', (records.length - replacements_count), replacements_count, replacements_list)
			} else {
				msg = sr('This will insert $1 new shift(s) into the system. Proceed?', records.length)
			}

			this.$confirm({
				title: 'Are you sure?',
				text: msg,
				acceptText: 'Batch Add Shifts',
				dialogMaxWidth: 600
			}).then(y => {
				// note that we send shifts (plural) here instead of shift
				this.$store.dispatch('save_shift', {shifts: records}).then((result)=>{
					let msg = sr('$1 shift(s) created or updated', result)
					this.$alert(msg).then(y=>{
						this.$emit('close')
					})
				})

			}).catch(n=>{console.log(n)}).finally(f=>{});
		},
	}
}
</script>

<style lang="scss">
</style>
